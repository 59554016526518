@use '../../styleguide/colors.scss';

.wrapper {
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding: 0px 6px;
  color: var(--fill-black-hover, #555);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-weight: 400;
  font-stretch: condensed;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.star {
  color: colors.$rating;
}

.noReviews {
  color: colors.$grey;
}

