$primary-color-light: var(--primary-color-light);
$primary-color-light-shade: var(--primary-color-light-shade);
$primary-color: var(--primary-color);
$primary-color-dark: var(--primary-color-dark);
$secondary-color-light: var(--secondary-color-light);
$secondary-color: var(--secondary-color);
$secondary-color-dark: var(--secondary-color-dark);
$background: var(--background);
$white: var(--white);
$grey: var(--grey);
$dark-grey: var(--dark-grey);
$default-text: var(--default-text);
$light-grey: var(--light-grey);
$secondary-text: var(--secondary-text);
$dark-text: var(--dark-text);
$succes: var(--succes);
$error: var(--error);
$rating: var(--rating);
$red: var(--red);
$homeHeaderColor: var(--home-header-color);
$homeHeaderBg: var(--home-header-bg);
$primary-color-bg: #D5ECFF;
$red-default: #FF4B50;
$light-grey-default: #E9E9E9;
$grey-default: #9B9B9B;
$green-default: #059C71;
$light-white: #f3f7fb;
$light-blue: #0089FF;